import React from 'react';
import classNames from 'classnames';

import css from './IconInbox.module.css';

/**
 * Success icon.
 *
 * @component
 * @param {Object} props
 * @param {string?} props.className add more style rules in addition to components own css.root
 * @param {string?} props.rootClassName overwrite components own css.root
 * @param {string?} props.fillColor overwrite components own css.fillColor
 * @returns {JSX.Element} SVG icon
 */
const IconInbox = props => {
    const { rootClassName, className, fillColor } = props;
    const classes = classNames(rootClassName || css.root, className);
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className={classes} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_32)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.25 2.5H3.75C3.05964 2.5 2.5 3.05964 2.5 3.75V16.25C2.5 16.9404 3.05964 17.5 3.75 17.5H16.25C16.9404 17.5 17.5 16.9404 17.5 16.25V3.75C17.5 3.05964 16.9404 2.5 16.25 2.5ZM16.25 3.75V11.875H14.0078C13.6764 11.8741 13.3584 12.0061 13.125 12.2414L11.6164 13.75H8.38359L6.875 12.2414C6.64139 12.0059 6.32311 11.8739 5.99141 11.875H3.75V3.75H16.25ZM16.25 16.25H3.75V13.125H5.99141L7.5 14.6336C7.73361 14.8691 8.05189 15.0011 8.38359 15H11.6164C11.9481 15.0011 12.2664 14.8691 12.5 14.6336L14.0086 13.125H16.25V16.25Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0_1_32">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    );
};

export default IconInbox;
